import * as React from "react";
import Layout from "../components/Layout";
import { Link, StaticQuery, graphql } from "gatsby";
import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";
import PlayerCard from "../components/Team/PlayerCard";
import PlayerTable from "../components/Team/PlayerTable";

const Squad = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={`true`} backgroundColour={`light`}>
      <Seo
        title={"Squad" + meta.sitePageTitleSep + meta.siteTitle}
        slug={meta.siteUrl + "/squad/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Squad"}
        facebookTitle={"Squad"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <StaticQuery
        query={graphql`
          query SquadListQuery {
            players: allGooglePlayersSheet(filter: { left: { ne: true } }) {
              edges {
                node {
                  id
                  name
                  left
                  positions
                  image
                  appearances
                  goals
                  mOM
                  runnerUp
                }
              }
            }
          }
        `}
        render={(data) => (
          <div className="container-fluid py-5 mt-5">
            <h2 className="mb-4 text-center">The Squad</h2>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="row">
                    {data.players.edges.map((player, index) => (
                      <div
                        className="col-6 col-md-3 col-lg-2 mb-4"
                        key={player.node.id}
                      >
                        <PlayerCard
                          thumbnail={player.node.image}
                          title={player.node.name}
                          intro={player.node.intro}
                          positions={player.node.positions}
                          roles={player.node.roles}
                        />
                      </div>
                    ))}
                    <div className="col-6 col-md-3 col-lg-2 mb-4">
                      <div className="card rounded-sm h-100 bg-secondary">
                        <Link
                          className="stretched-link text-center d-block h4 py-5 mb-0 text-white"
                          style={{ color: "#ffffff!important" }}
                          to="/contact/"
                        >
                          Join Us?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 mt-5">
                  <h2 className="mb-4 text-center">All Time Performance Table</h2>
                  {/* {JSON.stringify(data.players.edges)} */}
                  <PlayerTable players={data.players.edges} />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </Layout>
  );
};

export default Squad;
