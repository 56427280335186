import React from "react";
// import Img from "gatsby-image";

const PlayerCard = ({ thumbnail, title, intro, positions }) => {
  return (
    <article className="card bg-primary rounded-sm h-100 overflow-hidden">
      <div className="card-body- p-2 pb-0 pb-lg-0 p-lg-3">
        {!!positions && (
          <div className="post-meta text-success small font-weight-bold mb-0">
            {positions.split(", ").map((position) => (
              <React.Fragment key={position + title}>
                {![
                  "Manager",
                  "Assistant Manager",
                  "Assistant",
                  "Captain",
                  "Vice Captain",
                ].includes(position) && (
                  <span className="text-nowrap mr-1">{position}</span>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        <h4 className="h6 mb-0 post-title">
          <span className="d-block">{title}</span>
          {/* <span className="d-block">{title.split(" ")[1]}</span> */}
        </h4>
        <div className="h6 small text-nowrap">
          {positions &&
            positions
              .split(", ")
              .map((position, index) => (
                <React.Fragment key={position + title}>
                  {[
                    "Manager",
                    "Assistant Manager",
                    "Assistant",
                    "Captain",
                    "Vice Captain",
                  ].includes(position) && <>{position}</>}
                </React.Fragment>
              ))}
          <span className="opacity-0">Player</span>
        </div>

        {!!intro && <p className="post-meta">{intro}</p>}
      </div>
      {thumbnail && (
        <img
          src={thumbnail}
          className="player-image card-img-top"
          alt={title + " - Image"}
        />
      )}
    </article>
  );
};
export default PlayerCard;
