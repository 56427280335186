import React from "react";

const PlayerTable = ({ players }) => {
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      var direction = "ascending";
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (sortConfig.key !== "name") {
            direction = "descending";
          }

          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === direction ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === direction ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  var sortedPlayers = [];
  for (const player of players) {
    sortedPlayers.push(player.node);
  }
  const { items, requestSort, sortConfig } = useSortableData(sortedPlayers);

  return (
    <div className="mb-5 table-responsive">
      <table className="table  table-striped table-bordered small">
        <thead className="thead-black bg-secondary">
          <tr>
            <th
              onClick={() => requestSort("name")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "name"
              )}`}
              role="button"
            >
              Name
            </th>
            {/* <th
              onClick={() => requestSort("PositionPoints")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "PositionPoints"
              )}`}
              role="button"
            >
              Positions
            </th> */}
            {/* <th
              onClick={() => requestSort("Training")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "Training"
              )}`}
              role="button"
            >
              Training
            </th> */}
            <th
              onClick={() => requestSort("appearances")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "Appearances"
              )}`}
              role="button"
            >
              Appearances
            </th>
            {/* <th
              onClick={() => requestSort("Sub")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "Sub"
              )}`}
              role="button"
            >
              Sub
            </th> */}
            <th
              onClick={() => requestSort("goals")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "Goals"
              )}`}
              role="button"
            >
              Goals
            </th>
            {/* <th
              onClick={() => requestSort("Assists")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "Assists"
              )}`}
              role="button"
            >
              Assists
            </th> */}
            <th
              onClick={() => requestSort("mOM")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "MOM"
              )}`}
              role="button"
            >
              MOM
            </th>
            <th
              onClick={() => requestSort("runnerUp")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "RunnerUp"
              )}`}
              role="button"
            >
              RunnerUp
            </th>
            {/* <th
              onClick={() => requestSort("TOTAL_SCORE")}
              className={`cursor-pointer text-center ${getClassNamesFor(
                "TOTAL_SCORE"
              )}`}
              role="button"
            >
              Player Score
            </th> */}
          </tr>
        </thead>

        <tbody>
          {items.map((player, index) => (
            <tr key={`player_${index}`}>
              <td className="font-weight-bold">{player.name}</td>
              {/* <td className="text-center">{player.Training}</td> */}
              {/* <td className="text-center">{player.Starts}</td> */}
              {/* <td className="text-center">{player.SubsAppearances}</td> */}
              <td className="text-center">{player.appearances}</td>
              <td className="text-center">{player.goals}</td>
              {/* <td className="text-center">{player.Assists}</td> */}
              <td className="text-center">{player.mOM}</td>
              <td className="text-center">{player.runnerUp}</td>
              {/* <td className="text-center">{Math.ceil(player.TOTAL_SCORE)}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerTable;
